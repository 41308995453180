




























import {
  Component,
  Prop,
  Vue, Watch,
} from 'vue-property-decorator';
import { Camera } from '@/models/entities/Camera';
import { User } from '@/models/entities/User';
import { Lesson } from '@/models/entities/Lesson';
import { Course } from '@/models/entities/Course';
import { Polygon } from '@/models/entities/Polygon';

@Component({})
export default class LessonCamerasList extends Vue {
  @Prop({ required: true })
  cameras!: Camera[];
  @Prop({ required: true })
  forUser!: User;
  @Prop({ required: true })
  polygon!: Polygon;

  @Prop({ required: true })
  course!: Course;
  @Prop({ required: true })
  lesson!: Lesson;

  @Prop({ required: true })
  leases!: { [cellId: number]: true };

  currentDt: Date = new Date();
  currentDtTimer: number | null = null;

  mounted() {
    this.currentDtTimer = setInterval(() => {
      this.currentDt = new Date();
    }, 10_000);
  }

  destroyed() {
    if (this.currentDtTimer) {
      clearInterval(this.currentDtTimer);
    }
  }

  get isListShown(): boolean {
    return true;
  }

  get isLessonTime(): boolean {
    return this.lesson.isLessonTime(this.currentDt);
  }

  get isTeacher(): boolean {
    return this.course.isCourseTeacher(this.forUser);
  }

  get isStudent(): boolean {
    return this.course.isCourseStudent(this.forUser);
  }

  get isAdmin(): boolean {
    return this.forUser.isSuperAdmin
      || this.polygon.isUserAdmin(this.forUser)
      || this.course.isCourseAdmin(this.forUser);
  }

  get filteredCameras(): Camera[] {
    if (this.isAdmin) {
      return this.cameras;
    }

    if (!this.isLessonTime) {
      return [];
    }

    if (this.isTeacher) {
      return this.cameras;
    }

    if (this.isStudent) {
      return this.cameras.filter((c) => {
        return Boolean(c.cell && this.leases[c.cell.cellId]);
      });
    }

    return [];
  }

  @Watch('filteredCameras', { immediate: true })
  onFilteredCamerasChange(newValue: Camera[]): void {
    this.$emit('cameras-updated', newValue || []);
  }
}
